
















import Vue from 'vue';
import { isMobile } from 'mobile-device-detect';
import ShopItems from './ShopItems.vue';
import CartDrawer from './CartDrawer.vue';
import Cart from './Cart.vue';

export default Vue.extend({
  name: 'Shop',
  computed: {
    isMobile: () => isMobile,
  },
  components: {
    ShopItems,
    CartDrawer,
    Cart,
  },
});
