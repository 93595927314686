<template>
  <Login />
</template>

<style lang="scss">
  html {
    background: url(../assets/login.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
</style>

<script>
// @ is an alias to /src
import Login from '@/components/login/Login.vue';

export default {
  name: 'login',
  components: {
    Login,
  },
};
</script>
