






















































import Shop from '@/components/shop/Shop.vue';
import Stats from '@/components/stats/Stats.vue';
import User from '@/components/user/User.vue';
import { handleError } from '@/utils/utils';
import Vue from 'vue';

export default Vue.extend({
  name: 'login',

  props: {
    view: {
      required: false,
      type: String,
    },
  },
  async mounted() {
    Promise.all([this.$store.dispatch('getAllUsers'), this.$store.dispatch('getMe'), this.$store.dispatch('getAllProducts')])
      .catch((error) => handleError(this.$toasted, this.$router, error));
  },
  methods: {
    navigateTo(route: string) {
      this.$router.push({ name: 'main', params: { view: route } });
    },
  },
  components: {
    Shop,
    Stats,
    User,
  },
});
