/* ============
 * Mutation types for the user module
 * ============
 */
export const FETCH_HISTORY = 'FETCH_HISTORY';
export const SET_ALL_USERS = 'SET_ALL_USERS';
export const SET_ME = 'SET_ME';
export const NEW_BALANCE = 'NEW_BALANCE';

export default {
  FETCH_HISTORY,
  SET_ALL_USERS,
  SET_ME,
  NEW_BALANCE,
};
