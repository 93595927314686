import {
  CustomPurchase, ErrorResponse, Item, ItemPurchase, Me, User, PurchaseHistoryResponse, TimelineStatsData, BingeStatsData,
} from '@/types/types';
import axios, { AxiosResponse } from 'axios';
import { concat } from 'lodash';
import { getToken, setToken } from './utils';

interface LoginData {
  authToken: string;
}

interface PurchaseResponse {
  newBalance: number;
}

interface DepositResponse {
  balance: number;
}

interface MeResponse {
  user: User;
  balance: number;
  favourites: number[];
}

interface ApiPurchaseItem {
  productId?: number;
  count: number;
  otherItem?: {
    amount: number;
    description: string;
  }
}

type ApiResponse<T> = ErrorResponse | T

function isErroredResponse(resp: ApiResponse<User>): resp is ErrorResponse {
  return !!(resp as ErrorResponse).status && (resp as ErrorResponse).status >= 400;
}

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_PREFIX,
  headers: {
    'x-paitsio-id': {
      toString() {
        const token = getToken();
        console.log(token);
        if (token) {
          return token;
        }
        return '';
      },
    },
  },
});

async function login(data: LoginData) {
  const { data: respData, headers }: AxiosResponse<ApiResponse<User>> = await axiosInstance.post('/auth/login', data);

  if (isErroredResponse(respData)) {
    return Promise.reject(respData);
  }
  setToken(headers['x-paitsio-id']);
  return {
    ok: true,
    userData: respData,
    message: 'Login succesful!',
  };
}

async function getUsers() {
  const resp: AxiosResponse<ApiResponse<User[]>> = await axiosInstance.get('/user/all');
  return {
    ok: true,
    users: resp.data,
  };
}

async function getAllProducts() {
  const resp: AxiosResponse<ApiResponse<Item>> = await axiosInstance.get('/product/all');
  return {
    ok: true,
    products: resp.data,
  };
}

async function tab(items: ItemPurchase[], customItems: CustomPurchase[]) {
  const normals: ApiPurchaseItem[] = items.map((x) => {
    const { info: _info, id, ...rest } = x;
    return { ...rest, productId: id };
  });
  const customs: ApiPurchaseItem[] = customItems.map((customItem) => ({
    count: 1,
    otherItem: {
      amount: customItem.amount,
      description: customItem.description || '',
    },
  }));
  const payload = {
    purchase: concat(normals, customs),
  };
  const tabData: PurchaseResponse = (await axiosInstance.post('/purchase', payload)).data;
  return tabData.newBalance;
}

async function deposit(amount: number) {
  const payload = { amount };
  const { balance } = (await axiosInstance.post('deposit', payload)).data;
  return balance;
}

async function getMeStatus(): Promise<Me> {
  const meData: MeResponse = (await axiosInstance.get('user/me')).data;
  return { ...meData.user, balance: meData.balance, favourites: meData.favourites || [] };
}

async function requestMoreHistory(offset: number) {
  const historyData: PurchaseHistoryResponse = (await axiosInstance.get('purchase/history/me', { params: { page: offset, pageSize: 5 } })).data;
  return historyData;
}

async function requestTotalTimelineStats(): Promise<TimelineStatsData[]> {
  const { data } = await axiosInstance.get('stats/timeline');
  return data.map((x: {yearMonth: string, count: string}) => ({ ...x, count: parseInt(x.count, 10) }));
}

async function requestBingeStats(): Promise<BingeStatsData[]> {
  const { data } = await axiosInstance.get('stats/bingers');
  return data.map((x: {id: string, count: string}) => ({ id: parseInt(x.id, 10), count: parseInt(x.count, 10) }));
}

export {
  login, tab, requestMoreHistory, getUsers, getAllProducts, deposit, getMeStatus, PurchaseHistoryResponse, requestTotalTimelineStats, requestBingeStats,
};
