












import Vue from 'vue';
import GSignin from './GoogleSignIn.vue';

export default Vue.extend({
  name: 'Login',
  components: {
    GSignin,
  },
});
