



















import Vue from 'vue';
import { login } from '../../utils/api';

export default Vue.extend({
  name: 'GSignIn',
  data() {
    return {
      error: '',
    };
  },
  methods: {
    async initiateLogin() {
      const authCode = await this.$gAuth.getAuthCode();
      try {
        const response = await login({ authToken: authCode });
        if (response.ok) {
          this.$toasted.success(response.message, {
            duration: 2000,
            position: 'bottom-center',
          });
        } else {
          this.$toasted.error(response.message, {
            duration: 2000,
            position: 'bottom-center',
          });
        }
      } catch (err) {
        console.log(err);
      }
      this.$router.push({ name: 'main', params: { view: 'shop' } });
    },
  },
  components: {},
});
