




















import { mapGetters, mapMutations } from 'vuex';
import * as _ from 'lodash';
import Vue from 'vue';
import { Item } from '@/types/types';
import ItemList from '@/components/shop/ItemList.vue';
import { ADD_TO_CART } from '../../store/modules/item/mutation-types';

export default Vue.extend({
  data(): {search: string} {
    return {
      search: '',
    };
  },
  methods: {
    ...mapMutations({
      addToCart: ADD_TO_CART,
    }),
  },
  computed: {
    ...mapGetters(['itemsList', 'favoriteIds', 'itemById']),
    filteredDrinks(): {fav: Item[], normal: Item[]} {
      const normal: Item[] = _.cloneDeep(this.itemsList).sort((a: Item, b: Item) => a.name.localeCompare(b.name));
      const favs: Item[] = _.cloneDeep(this.favoriteIds).reduce((acc:Item[], val: number) => {
        acc.push(this.itemById(val));
        return acc;
      }, []).sort((a: Item, b: Item) => a.name.localeCompare(b.name));
      if (!this.search) {
        return { fav: favs, normal };
      }
      const filteredNormal = normal.filter((x) => x.name.toLowerCase().includes(this.search.toLowerCase()));
      const filteredFavs = favs.filter((x) => x.name.toLowerCase().includes(this.search.toLowerCase()));
      return { normal: filteredNormal, fav: filteredFavs };
    },
  },
  components: {
    ItemList,
  },
});
