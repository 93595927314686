/* ============
 * Mutations for the item module
 * ============
 */

import {
  BingeStatsData,
  CustomPurchase, Item, ItemPurchase, ItemState, TimelineStatsData,
} from '@/types/types';
import {
  REMOVE_FROM_CART, ADD_TO_CART, CLEAR_CART, SET_PRODUCTS, ADD_CUSTOM_TO_CART, REMOVE_CUSTOM_FROM_CART, SET_TIMELINE_STATS, SET_BINGE_STATS,
} from './mutation-types';

export default {
  [ADD_TO_CART](state: ItemState, { id, count = 1 }: {id: number, count: number}) {
    const newItems = [...state.cartItems];
    const existingItem = newItems.find((x) => x.id === id);
    if (existingItem) {
      newItems[newItems.indexOf(existingItem)] = {
        ...existingItem,
        count: existingItem.count + count,
      };
    } else {
      newItems.push({ id, info: state.items[id], count });
    }
    Object.assign(state, { cartItems: newItems });
  },

  [REMOVE_FROM_CART](state: ItemState, { id, count }: {id: number, count: number}) {
    let newItems: ItemPurchase[] = [...state.cartItems];
    const item = newItems.find((x) => x.id === id);
    if (!count || (!!item && item.count - count <= 0)) {
      newItems = state.cartItems.filter((x) => x.id !== id);
    } else if (item) {
      newItems[newItems.indexOf(item)] = {
        ...item,
        count: item.count - count,
      };
    }
    Object.assign(state, { cartItems: newItems });
  },
  [CLEAR_CART](state: ItemState) {
    Object.assign(state, { cartItems: [], customCartItems: [] });
  },
  [SET_PRODUCTS](state: ItemState, products: Item[]) {
    const items = products.reduce((acc, val) => {
      acc[val.id] = val;
      return acc;
    }, {} as {[key: string]:Item});
    Object.assign(state, { items });
  },
  [ADD_CUSTOM_TO_CART](state: ItemState, item: CustomPurchase) {
    const newCustomItems = [...state.customCartItems, item];
    Object.assign(state, { customCartItems: newCustomItems });
  },
  [REMOVE_CUSTOM_FROM_CART](state: ItemState, index: number) {
    const newCustomItems = [...state.customCartItems].filter((_x, idx) => idx !== index);
    Object.assign(state, { customCartItems: newCustomItems });
  },
  [SET_TIMELINE_STATS](state: ItemState, timelineStats: TimelineStatsData[]) {
    Object.assign(state, { totalTimelineStats: timelineStats });
  },
  [SET_BINGE_STATS](state: ItemState, bingeStats: BingeStatsData[]) {
    Object.assign(state, { bingeStats });
  },
};
