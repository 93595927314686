/* ============
 * Actions for the user module
 * ============
 */
import {
  requestMoreHistory as requestMoreHistoryFromApi, getUsers, deposit as apiDeposit, getMeStatus,
} from '@/utils/api';
import { RootState } from '@/types/types';
import { ActionContext } from 'vuex';
import {
  FETCH_HISTORY, SET_ALL_USERS, NEW_BALANCE, SET_ME,
} from './mutation-types';

const requestMoreHistory = async ({ commit }: ActionContext<RootState, RootState>, page: number) => {
  const newHistory = await requestMoreHistoryFromApi(page);
  commit(FETCH_HISTORY, newHistory);
};

const getAllUsers = async ({ commit }: ActionContext<RootState, RootState>) => {
  const allUsers = await getUsers();
  commit(SET_ALL_USERS, allUsers.users);
};

const deposit = async ({ commit }: ActionContext<RootState, RootState>, amount: number) => {
  const newTab = await apiDeposit(amount);
  commit(NEW_BALANCE, newTab);
};

const getMe = async ({ commit }: ActionContext<RootState, RootState>) => {
  const me = await getMeStatus();
  commit(SET_ME, me);
};

export default {
  requestMoreHistory,
  getAllUsers,
  deposit,
  getMe,
};
