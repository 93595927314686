















































import { mapGetters } from 'vuex';
import { formatNumber, handleError, parseStringToNumber } from '@/utils/utils';
import Vue from 'vue';
import { ADD_CUSTOM_TO_CART } from '@/store/modules/item/mutation-types';

export default Vue.extend({
  props: {
    type: {
      type: String,
      validator(value: string) {
        return ['withdraw', 'deposit'].includes(value);
      },
    },
  },
  data: (): {
    dialog: boolean;
    amount?: string;
    desc: string;
    error: boolean | string;
  } => ({
    dialog: false,
    amount: undefined,
    desc: '',
    error: '',
  }),
  methods: {
    formatNumber,
    parseStringToNumber,
    notify(message: string, emoji?: string, mode: 'success' | 'error' = 'success') {
      this.$toasted[mode](
        `${emoji ? `<span class="emoji">${emoji}</span>` : ''}<span>${message}€</span>${emoji ? `<span class="emoji">${emoji}</span>` : ''}`,
        {
          duration: 2000,
          position: 'bottom-center',
        },
      );
    },
    getAmount() {
      return this.amount ? parseStringToNumber(this.amount) : 0;
    },
    async deposit() {
      try {
        await this.$store.dispatch('deposit', this.amount);
        this.notify(`Deposit successfull, new balance: ${formatNumber(this.me.balance)}`, '💰');
        this.close();
      } catch (err) {
        handleError(this.$toasted, this.$router, err);
      }
    },
    withdraw(): void {
      this.$store.commit(ADD_CUSTOM_TO_CART, { amount: this.getAmount(), description: this.desc });
      this.close();
    },
    close() {
      this.amount = '0';
      this.error = '';
      this.dialog = false;
    },
  },
  computed: {
    ...mapGetters(['me']),
    title(): string {
      return this.isWithdraw ? 'Tab custom amount' : 'Deposit money';
    },
    isWithdraw(): boolean {
      return this.type === 'withdraw';
    },
  },
});
