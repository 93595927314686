































import 'zingchart/es6';
// @ts-ignore: lol ei jagee
import zingchartVue from 'zingchart-vue';
import { BingeStatsData, TimelineStatsData } from '@/types/types';
import Vue from 'vue';
import { mapGetters } from 'vuex';

const capitalize = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export default Vue.extend({
  name: 'Stats',
  data() {
    return {
      bingePage: 0,
      totalPage: 0,
    };
  },
  watch: {
    getTotalTimelineValues(val) {
      console.log(Math.ceil(val.length / 5));
      this.totalPage = Math.floor(val.length / 5);
    },
  },
  mounted() {
    this.$store.dispatch('getBingeStats');
    this.$store.dispatch('getTotalTimelineStats');
  },
  components: {
    zingchartVue,
  },
  methods: {
    next(value: 'bingePage' | 'totalPage'): void {
      this[value] += 1;
    },
    previous(value: 'bingePage' | 'totalPage'): void {
      this[value] -= 1;
    },
  },
  computed: {
    ...mapGetters(['getBingeValues', 'userById', 'getTotalTimelineValues']),
    bingeData(): any {
      const data = [...(this.getBingeValues as BingeStatsData[])].sort((x, y) => y.count - x.count);
      const values = data.map((x) => x.count).slice(this.bingePage * 5, (this.bingePage + 1) * 5);
      const labels = data.reduce((acc, x) => {
        const user = this.userById(x.id);
        if (!user) {
          return [...acc, ''];
        }
        const emailParsed = user.email.split(/\.|@/);
        const firstName = capitalize(emailParsed[0]);
        const lastNameTwoCharacters = capitalize(emailParsed[1].substring(0, 2));
        return acc.includes(firstName)
          ? [...acc, `${firstName} ${lastNameTwoCharacters}`]
          : [...acc, firstName];
      }, [] as string[]).slice(this.bingePage * 5, (this.bingePage + 1) * 5);
      return {
        gui: {
          contextMenu: {
            button: {
              visible: false,
            },
          },
        },
        title: {
          text: 'Bingiest Binger',
          'font-color': '#7E7E7E',
          backgroundColor: 'none',
          'font-size': '22px',
          alpha: 1,
          'adjust-layout': true,
        },
        plot: {
          'value-box': {
            text: '%v',
          },
        },
        type: 'bar',
        'scale-x': {
          values: labels,
        },
        series: [
          {
            values,
          },
        ],
      };
    },
    totalTimelineData(): any {
      const data = [...(this.getTotalTimelineValues as TimelineStatsData[])].sort((x, y) => (new Date(x.yearMonth) > new Date(y.yearMonth) ? 1 : -1))
        .slice(this.totalPage * 5, (this.totalPage + 1) * 5);
      const values = data.map((x) => x.count);
      const labels = data.map((x) => x.yearMonth);
      return {
        gui: {
          contextMenu: {
            button: {
              visible: false,
            },
          },
        },
        title: {
          text: 'Overall consumption',
          'font-color': '#7E7E7E',
          backgroundColor: 'none',
          'font-size': '22px',
          alpha: 1,
          'adjust-layout': true,
        },
        type: 'line',
        'scale-x': {
          values: labels,
        },
        series: [
          {
            values,
          },
        ],
      };
    },
  },
});
