




import Vue from 'vue';
import { tokenValid } from './utils/utils';

interface UpdateEvent extends Event {
  type: 'swUpdated';
  detail: any;
}

export default Vue.extend({
  name: 'App',
  data(): { refreshing: boolean; registration: any } {
    return {
      refreshing: false,
      registration: null,
    };
  },
  created() {
    // Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener<any>('swUpdated', this.showRefreshUI, {
      once: true,
    });
    // Refresh all open app tabs when a new service worker is installed.
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }
  },
  mounted() {
    console.log(tokenValid());
    if (tokenValid()) {
      window.history.pushState({}, 'Piikki', '/#/main/shop');
    }
  },
  methods: {
    showRefreshUI(e: UpdateEvent) {
      // Display a snackbar inviting the user to refresh/reload the app due
      // to an app update being available.
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      this.registration = e.detail;
      this.$toasted.success('New PWA version available!', {
        action: {
          text: 'Update',
          onClick: this.refreshApp,
        },
        position: 'bottom-center',
      });
    },
    refreshApp() {
      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage('skipWaiting');
    },
  },
});
