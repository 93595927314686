



























































































































































































import { mapGetters, mapMutations, mapActions } from 'vuex';
import { isMobile } from 'mobile-device-detect';
import Vue from 'vue';
import { formatNumber, handleError } from '@/utils/utils';
import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  REMOVE_CUSTOM_FROM_CART,
} from '../../store/modules/item/mutation-types';

export default Vue.extend({
  props: {
    disableTab: Boolean,
  },
  methods: {
    ...mapMutations({
      addToCart: ADD_TO_CART,
      removeFromCart: REMOVE_FROM_CART,
      removeCustomFromCart: REMOVE_CUSTOM_FROM_CART,
    }),
    formatNumber,
    remove({ id, count }: { id: number; count: number }) {
      this.removeFromCart({ id, count });
    },
    ...mapActions(['tab']),
    async tabMe(): Promise<void> {
      try {
        const order = this.cartItems;
        const customOrder = this.customCartItems;
        await this.tab({ items: order, customItems: customOrder });
        this.$toasted.success(
          `<span class="emoji">🍻</span><span>Tabbing successfull, ${formatNumber(
            this.me.balance,
          )}€ left</span><span class="emoji">🍻</span>`,
          {
            duration: 2000,
            position: 'bottom-center',
          },
        );
      } catch (err) {
        handleError(this.$toasted, this.$router, err);
      }
    },
  },
  computed: {
    ...mapGetters(['cartItems', 'me', 'total', 'customCartItems']),
    isMobile: (): boolean => isMobile,
    hasItems(): boolean {
      return this.cartItems.length > 0 || this.customCartItems.length > 0;
    },
  },
});
