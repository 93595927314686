/* ============
 * State of the user module
 * ============
 */
import { addMinutes } from 'date-fns';

export default {
  users: {},
  me: { favourites: [] },
  purchaseHistoryAvailable: 10,
  purchaseHistory: [],
};
