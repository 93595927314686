// Taken from here with types fixed for TS 4: https://github.com/guruahn/vue-google-oauth2

import _Vue from 'vue';

declare global {
  interface Window { gapi: any; }
}

const googleAuth = ((): any => {
  const installClient = (): Promise<void> => {
    const apiUrl = 'https://apis.google.com/js/api.js';
    return new Promise((resolve) => {
      const script: any = document.createElement('script');
      script.src = apiUrl;
      const onChange = () => {
        if (!script.readyState || new RegExp('/loaded|complete/').test(script.readyState)) {
          setTimeout(() => {
            resolve();
          }, 500);
        }
      };
      script.onreadystatechange = onChange;
      script.onload = onChange;
      document.getElementsByTagName('head')[0].appendChild(script);
    });
  };

  const initClient = (config: any) => new Promise((resolve, reject) => {
    window.gapi.load('auth2', () => {
      window.gapi.auth2.init(config)
        .then(() => {
          resolve(window.gapi);
        }).catch((error: any) => {
          reject(error);
        });
    });
  });

  function Auth(this: any) {
    this.GoogleAuth = null; /* window.gapi.auth2.getAuthInstance() */
    this.isAuthorized = false;
    this.isInit = false;
    this.prompt = null;
    this.isLoaded = () => {
      // tslint:disable-next-line
      console.warn('isLoaded() will be deprecated. You can use "this.$gAuth.isInit"');
      return !!this.GoogleAuth;
    };

    this.load = (config: any, prompt: string) => {
      installClient()
        .then(() => initClient(config))
        .then((gapi: any) => {
          this.GoogleAuth = gapi.auth2.getAuthInstance();
          this.isInit = true;
          this.prompt = prompt;
          this.isAuthorized = this.GoogleAuth.isSignedIn.get();
        }).catch((error) => {
          console.error(error);
        });
    };

    this.signIn = (successCallback: any, errorCallback: any) => new Promise((resolve, reject) => {
      if (!this.GoogleAuth) {
        if (typeof errorCallback === 'function') {
          errorCallback(false);
        }
        reject(new Error('failed to auth'));
        return;
      }
      this.GoogleAuth.signIn()
        .then((googleUser: any) => {
          if (typeof successCallback === 'function') {
            successCallback(googleUser);
          }
          this.isAuthorized = this.GoogleAuth.isSignedIn.get();
          resolve(googleUser);
        })
        .catch((error: any) => {
          if (typeof errorCallback === 'function') {
            errorCallback(error);
          }
          reject(error);
        });
    });

    this.getAuthCode = (successCallback: any, errorCallback: any) => new Promise((resolve, reject) => {
      if (!this.GoogleAuth) {
        if (typeof errorCallback === 'function') {
          errorCallback(false);
        }
        reject(new Error('failed to get authcode'));
        return;
      }
      this.GoogleAuth.grantOfflineAccess({ prompt: this.prompt })
        .then((resp: any) => {
          if (typeof successCallback === 'function') {
            successCallback(resp.code);
          }
          resolve(resp.code);
        })
        .catch((error: any) => {
          if (typeof errorCallback === 'function') {
            errorCallback(error);
          }
          reject(error);
        });
    });

    this.signOut = (successCallback: any, errorCallback: any) => new Promise((resolve, reject) => {
      if (!this.GoogleAuth) {
        if (typeof errorCallback === 'function') {
          errorCallback(false);
        }
        reject(new Error('failed to sign out'));
        return;
      }
      this.GoogleAuth.signOut()
        .then(() => {
          if (typeof successCallback === 'function') {
            successCallback();
          }
          this.isAuthorized = false;
          resolve(true);
        })
        .catch((error: any) => {
          if (typeof errorCallback === 'function') {
            errorCallback(error);
          }
          reject(error);
        });
    });
  }

  return new (Auth as any)();
})();

function installGoogleAuthPlugin(Vue: typeof _Vue, options?: any): void {
  // set config
  let GoogleAuthConfig: any = null;
  const GoogleAuthDefaultConfig = {
    scope: 'profile email',
  };
  let prompt = 'select_account';
  if (typeof options === 'object') {
    GoogleAuthConfig = Object.assign(GoogleAuthDefaultConfig, options);
    if (options.scope) {
      GoogleAuthConfig.scope = options.scope;
    }
    if (options.prompt) {
      prompt = options.prompt;
    }
    if (!options.clientId) {
      // tslint:disable-next-line
      console.warn('clientId is required');
    }
  } else {
    // tslint:disable-next-line
    console.warn('invalid option type. Object type accepted only');
  }

  // Install Vue plugin
  Object.defineProperties(Vue.prototype, {
    $gAuth: {
      get: () => googleAuth,
    },
  });
  googleAuth.load(GoogleAuthConfig, prompt);
}
// tslint:disable-next-line
export default installGoogleAuthPlugin;
