import { CustomPurchase, ErrorResponse, ItemPurchase } from '@/types/types';
import { AxiosError } from 'axios';
import jwtDecode from 'jwt-decode';
import VueRouter from 'vue-router';
import { Toasted, ToastOptions } from 'vue-toasted';

export function formatNumber(n: number) {
  return (Math.round(n * 100) / 100).toFixed(2);
}

export function getCartTotal(cart: ItemPurchase[]) {
  return cart.map((item) => item.info.price * item.count).reduce((a, b) => a + b, 0);
}

export function getCustomCartTotal(cart: CustomPurchase[]) {
  return cart.map((item) => item.amount).reduce((a, b) => a + b, 0);
}

export function parseStringToNumber(n: string) {
  return parseFloat(n.replace(',', '.').replace(' ', ''));
}

export function setToken(token: string) {
  localStorage.setItem('token', token);
}

export function removeToken() {
  localStorage.removeItem('token');
}

export function getToken() {
  return localStorage.getItem('token');
}

export function tokenValid() {
  try {
    const token = getToken();
    if (!token) {
      return false;
    }
    const decodedToken = jwtDecode(token);
    const expiry = new Date((decodedToken as { exp: number }).exp * 1000);
    return expiry > new Date();
  } catch (err) {
    return false;
  }
}

export function handleError(notify: Toasted, router: VueRouter, error: AxiosError<ErrorResponse>) {
  const toastedOptions: ToastOptions = {
    duration: 2000,
    position: 'bottom-center',
  };
  if (error.isAxiosError && (error.response?.status === 403 || error.response?.status === 401)) {
    removeToken();
    notify.error('Token invalid', toastedOptions);
    router.push({ name: 'login' });
    return;
  }
  if (error.response && error.response.data.message) {
    notify.error(error.response.data.message, toastedOptions);
  } else {
    notify.error(`Servu kusi jotain 🤷: ${error.message}`, toastedOptions);
  }
}
