/* ============
 * Mutations for the user module
 * ============
 */

import {
  Me, PurchaseHistoryResponse, User, UserState,
} from '@/types/types';
import {
  FETCH_HISTORY, SET_ALL_USERS, SET_ME, NEW_BALANCE,
} from './mutation-types';

export default {
  [FETCH_HISTORY](state: UserState, history: PurchaseHistoryResponse) {
    const newItems = [...state.purchaseHistory, ...history.items];
    Object.assign(state, { purchaseHistory: newItems, purchaseHistoryAvailable: parseInt(history.totalItems, 10) });
  },
  [SET_ALL_USERS](state: UserState, users: User[]) {
    const newUsers = users.reduce((acc, val) => {
      acc[val.id] = val;
      return acc;
    }, {} as {[key: string]: User});
    Object.assign(state, { users: newUsers });
  },
  [SET_ME](state: UserState, me: Me) {
    Object.assign(state, { me });
  },
  [NEW_BALANCE](state: UserState, amount: number) {
    const newBalance = amount || state.me.balance;
    Object.assign(state, { me: { ...state.me, balance: newBalance } });
  },
};
