

























































import { Item } from '@/types/types';
import CustomAmountModal from '@/components/modal/CustomAmountModal.vue';
import Vue from 'vue';
import { mapMutations } from 'vuex';
import { ADD_TO_CART } from '../../store/modules/item/mutation-types';

export default Vue.extend({
  props: {
    action: Function,
    appendCustom: Boolean,
    items: Array,
  },
  methods: {
    ...mapMutations({
      addToCart: ADD_TO_CART,
    }),
    itemClicked(item: Item) {
      this.action(item);
    },
  },
  components: {
    CustomAmountModal,
  },
});
