/* ============
 * Getters for the item module
 * ============
 */

import { ItemState } from '@/types/types';
import { getCartTotal, getCustomCartTotal } from '@/utils/utils';

export default {
  itemsList: (state: ItemState) => Object.values(state.items),
  items: (state: ItemState) => state.items,
  itemById: (state: ItemState) => (id: number) => state.items[id],
  cartItems: (state: ItemState) => state.cartItems,
  customCartItems: (state: ItemState) => state.customCartItems,
  total: (state: ItemState) => getCartTotal(state.cartItems) + getCustomCartTotal(state.customCartItems),
  getBingeValues: (state: ItemState) => state.bingeStats,
  getTotalTimelineValues: (state: ItemState) => state.totalTimelineStats,
};
