import Vue from 'vue';
// @ts-nocheck
import GAuth from '@/gauth/gauth';
import Toasted from 'vue-toasted';
import Vuetify from 'vuetify';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import '@mdi/font/css/materialdesignicons.css';
import router from './router';
import store from './store';
import './registerServiceWorker';

const gauthOption = {
  clientId: '611281060111-doetdm8suor977oc1bgb664eeq6pd8co.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account',
};

Vue.use(Vuetify);
// @ts-nocheck
Vue.use(GAuth, gauthOption);
Vue.use(Toasted, { router, iconPack: 'material', className: 'toast' });

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
