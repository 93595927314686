/* ============
 * State of the account module
 * ============
 *
 * The initial state of the account module.
 */

const state = {
  items: {},
  cartItems: [],
  customCartItems: [],
  bingeStats: [],
  totalTimelineStats: [],
};

export default state;
