
























import { mapGetters } from 'vuex';
import Vue from 'vue';
import Cart from '@/components/shop/Cart.vue';

interface TouchWithTime {
  identifier: number;
  clientY: number;
  timeStamp: number;
}

const animatedTransition = 'bottom 0.25s ease-in';
const noneTransition = 'unset';

export default Vue.extend({
  data(): {
    open: boolean;
    dragging: boolean;
    touchIdentifier: undefined | number;
    offset: number;
    velocity: number,
    touches: {[key: number]: TouchWithTime}
    drawerTransition: string
    } {
    return {
      open: false,
      dragging: false,
      touchIdentifier: undefined,
      offset: 0,
      velocity: 0,
      touches: {},
      drawerTransition: noneTransition,
    };
  },
  watch: {
    cartItems() {
      if (this.open) {
        this.$nextTick(() => {
          this.offset = (this.$refs.drawer as Element).clientHeight - (1.5 * 16 + 48);
        });
      }
    },
    customCartItems() {
      if (this.open) {
        this.$nextTick(() => {
          this.offset = (this.$refs.drawer as Element).clientHeight - (1.5 * 16 + 48);
        });
      }
    },
  },
  methods: {
    startDrag(event: TouchEvent) {
      if (!this.touchIdentifier && (this.cartItems.length > 0 || this.customCartItems.length > 0)) {
        this.dragging = true;
        this.touchIdentifier = event.touches[0]?.identifier;
        const touches: {[key: number]: TouchWithTime} = {};
        for (let i = 0; i < event.touches.length; i += 1) {
          const touch = event.touches[i]!;
          touches[touch.identifier] = { identifier: touch.identifier, clientY: touch.clientY, timeStamp: event.timeStamp };
        }
        this.touches = touches;
        this.drawerTransition = noneTransition;
      }
    },
    drag(event: TouchEvent) {
      const maxDraverMove = (this.$refs.drawer as Element).clientHeight - (1.5 * 16 + 48);
      if (this.touchIdentifier !== undefined) {
        event.preventDefault();
        let touch: Touch | undefined;
        for (let i = 0; i < event.changedTouches.length; i += 1) {
          if (event.changedTouches[i]!.identifier === this.touchIdentifier) {
            touch = event.changedTouches[i]!;
          }
        }
        if (touch) {
          const oldTouch = this.touches[this.touchIdentifier];
          const moveDiff = touch!.clientY - (oldTouch.clientY || 0);
          const timeDiff = event.timeStamp - (oldTouch.timeStamp || 0);
          if (this.offset + moveDiff < maxDraverMove && this.offset + moveDiff >= 0) {
            this.velocity = moveDiff / timeDiff;
            this.offset += moveDiff;
          } else if (this.offset + moveDiff > maxDraverMove) {
            this.offset = maxDraverMove;
          } else if (this.offset + moveDiff <= 0) {
            this.offset = 0;
          } else {
            this.offset = this.open ? maxDraverMove : 0;
          }
          this.touches = { ...this.touches, [touch.identifier]: { identifier: touch.identifier, clientY: touch.clientY, timeStamp: event.timeStamp } };
        }
      }
    },
    endDrag(event: TouchEvent) {
      const maxDraverMove = (this.$refs.drawer as Element).clientHeight - (1.5 * 16 + 48);
      event.preventDefault();
      this.drawerTransition = animatedTransition;
      this.dragging = false;
      this.touchIdentifier = undefined;
      this.touches = {};
      if (this.open) {
        if (this.offset < maxDraverMove / 2 || this.velocity < -1) {
          this.offset = 0;
          this.open = false;
        } else {
          this.offset = maxDraverMove;
        }
      } else if (!this.open) {
        if (this.offset > maxDraverMove / 2 || this.velocity > 1) {
          this.offset = maxDraverMove;
          this.open = !this.open;
        } else {
          this.offset = 0;
        }
      }
      this.velocity = 0;
    },
    cancelDrag() {
      console.log('cancel');
      const maxDraverMove = (this.$refs.drawer as Element).clientHeight - (1.5 * 16 + 48);
      if (this.open) {
        this.offset = maxDraverMove;
      } else {
        this.offset = 0;
      }
      this.touchIdentifier = undefined;
      this.touches = {};
      this.velocity = 0;
      this.drawerTransition = animatedTransition;
    },
  },
  computed: {
    ...mapGetters(['cartItems', 'me', 'total', 'customCartItems']),
  },
  components: {
    Cart,
  },
});
