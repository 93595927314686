/* ============
 * Mutation types for the item module
 * ============
 */

export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const ADD_CUSTOM_TO_CART = 'ADD_CUSTOM_TO_CART';
export const REMOVE_CUSTOM_FROM_CART = 'REMOVE_CUSTOM_FROM_CART';
export const SET_TIMELINE_STATS = 'SET_TIMELINE_STATS';
export const SET_BINGE_STATS = 'SET_BINGE_STATS';

export default {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  CLEAR_CART,
  SET_PRODUCTS,
  ADD_CUSTOM_TO_CART,
  REMOVE_CUSTOM_FROM_CART,
  SET_TIMELINE_STATS,
  SET_BINGE_STATS,
};
