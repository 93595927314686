




























































































import { mapActions, mapGetters } from 'vuex';
import * as _ from 'lodash';
import { formatDistanceToNow } from 'date-fns';
import Vue from 'vue';
import { HistoricPurchase, PurchaseHistoryItem } from '@/types/types';
import {
  formatNumber, handleError, removeToken,
} from '@/utils/utils';
import HISTORY_PAGE_SIZE from '@/utils/constants';
import CustomAmountModal from '@/components/modal/CustomAmountModal.vue';

export default Vue.extend({
  name: 'User',
  data() {
    return {
      page: 1,
    };
  },
  mounted() {
    this.$store.dispatch('requestMoreHistory', 0).catch((error) => handleError(this.$toasted, this.$router, error));
  },
  methods: {
    ...mapActions(['requestMoreHistory']),
    logout() {
      removeToken();
      this.$toasted.success('Logged out', {
        duration: 2000,
        position: 'bottom-center',
      });
      this.$router.push({ name: 'login' });
    },
    getItemText(item: PurchaseHistoryItem) {
      if (item.type === 'CUSTOM') {
        return `Custom amount @ ${item.price}€`;
      } if (item.type === 'DEPOSIT') {
        return `Deposit @ ${item.price}€`;
      }
      return `${item.count} x ${item.name} @ ${item.price}€`;
    },
    async changePage(page: number) {
      this.page = page;
      if (page * HISTORY_PAGE_SIZE > this.getPurchaseHistory().length) {
        await this.requestMoreHistory(page - 1);
      }
    },
    getPurchaseSum(purchase: HistoricPurchase) {
      return _.sumBy(purchase.purchaseItems, (x) => {
        const asd = x.type === 'DEPOSIT' ? +1 : -1;
        return asd * x.price * x.count;
      });
    },
    formatDateDistance(dateString: string) {
      return formatDistanceToNow(new Date(dateString));
    },
    formatNumber,
  },
  computed: {
    ...mapGetters(['me', 'getPurchaseHistory', 'getPurchaseHistoryAvailable']),
  },
  components: {
    CustomAmountModal,
  },
});
