/* ============
 * Actions for the item module
 * ============
 */

import { RootState, ItemPurchase, CustomPurchase } from '@/types/types';
import {
  tab as apiTab, getAllProducts as apiGetAllProducts, requestTotalTimelineStats as apiGetTimelineStats, requestBingeStats as apiGetBingeStats,
} from '@/utils/api';
import { ActionContext } from 'vuex';
import * as types from './mutation-types';
import { NEW_BALANCE } from '../user/mutation-types';

const tab = async ({ commit }: ActionContext<RootState, RootState>, { items, customItems }: {items: ItemPurchase[], customItems: CustomPurchase[]}) => {
  console.log(items, customItems);
  const newTab = await apiTab(items, customItems);
  commit(NEW_BALANCE, newTab);
  commit(types.CLEAR_CART);
};

const getAllProducts = async ({ commit }: ActionContext<RootState, RootState>) => {
  const products = await apiGetAllProducts();
  commit(types.SET_PRODUCTS, products.products);
};

const getTotalTimelineStats = async ({ commit }: ActionContext<RootState, RootState>) => {
  const timeline = await apiGetTimelineStats();
  commit(types.SET_TIMELINE_STATS, timeline);
};

const getBingeStats = async ({ commit }: ActionContext<RootState, RootState>) => {
  const bingeData = await apiGetBingeStats();
  commit(types.SET_BINGE_STATS, bingeData);
};

export default {
  tab,
  getAllProducts,
  getTotalTimelineStats,
  getBingeStats,
};
