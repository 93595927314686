/* ============
 * Getters for the user module
 * ============
 */

import { UserState } from '@/types/types';
import * as _ from 'lodash';
import HISTORY_PAGE_SIZE from '@/utils/constants';

export default {
  users: (state: UserState) => state.users,
  userById: (state: UserState) => (id: number) => state.users[id],
  favoriteIds: (state: UserState) => state.me.favourites,
  me: (state: UserState) => state.me,
  // eslint-disable-next-line
  getPurchaseHistory: (state: UserState) => (page?: number) => {
    return !page ? state.purchaseHistory : _.slice(state.purchaseHistory, (page - 1) * HISTORY_PAGE_SIZE, page * HISTORY_PAGE_SIZE);
  },
  getPurchaseHistoryAvailable: (state: UserState) => state.purchaseHistoryAvailable,
};
